import VueRouter from 'vue-router';

// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location) {
// 	return originalPush.call(this, location).catch(err => err);
// }

let router = new VueRouter({
    routes: [
        {
            path: '/',
            redirect: '/dashboard'
        },
        {
            path: '/',
            component: () => import('../components/Home.vue'),
            meta: {
                title: '自述文件'
            },
            children: [
                {
                    name: 'dashboard',
                    path: '/dashboard',
                    component: () => import('../pages/Dashboard.vue'),
                    meta: {
                        title: '系统首页'
                    }
                },
                {
                    path: '/icon',
                    name: 'icon',
                    component: () => import('../pages/Icon.vue'),
                    meta: {
                        title: '自定义图标'
                    }
                },
                {
                    path: '/table',
                    name: 'table',
                    component: () => import('../pages/BaseTable.vue'),
                    meta: {
                        title: '基础表格'
                    }
                },
                {
                    path: '/tabs',
                    name: 'tabs',
                    component: () => import('../pages/Tabs.vue'),
                    meta: {
                        title: 'tab选项卡'
                    }
                },
                {
                    path: '/form',
                    name: 'form',
                    component: () => import('../pages/BaseForm.vue'),
                    meta: {
                        title: '基本表单'
                    }
                },
                {
                    // 富文本编辑器组件
                    path: '/editor',
                    name: 'editor',
                    component: () => import('../pages/VueEditor.vue'),
                    meta: {
                        title: '富文本编辑器'
                    }
                },
                {
                    // markdown组件
                    path: '/markdown',
                    name: 'markdown',
                    component: () => import('../pages/Markdown.vue'),
                    meta: {
                        title: 'markdown编辑器'
                    }
                },
                {
                    // 图片上传组件
                    path: '/upload',
                    name: 'upload',
                    component: () => import('../pages/Upload.vue'),
                    meta: {
                        title: '文件上传'
                    }
                },
                {
                    // vue-schart组件
                    path: '/charts',
                    component: () => import('../pages/BaseCharts.vue'),
                    meta: {
                        title: 'schart图表'
                    }
                },
                {
                    // 拖拽列表组件
                    path: '/drag',
                    component: () => import('../pages/DragList.vue'),
                    meta: {
                        title: '拖拽列表'
                    }
                },
                {
                    // 拖拽Dialog组件
                    path: '/dialog',
                    component: () => import('../pages/DragDialog.vue'),
                    meta: {
                        title: '拖拽弹框'
                    }
                },
                {
                    // 国际化组件
                    path: '/i18n',
                    component: () => import('../pages/I18n.vue'),
                    meta: {
                        title: '国际化'
                    }
                },
                {
                    // 权限页面
                    path: '/permission',
                    component: () => import('../pages/Permission.vue'),
                    meta: {
                        title: '权限测试',
                        permission: true
                    }
                },
                {
                    path: '/404',
                    component: () => import('../pages/404.vue'),
                    meta: {
                        title: '404'
                    }
                },
                {
                    path: '/403',
                    component: () => import('../pages/403.vue'),
                    meta: {
                        title: '403'
                    }
                },
                {
                    path: '/donate',
                    component: () => import('../pages/Donate.vue'),
                    meta: {
                        title: '支持作者'
                    }
                },
                {
                    path: '/changeinfo',
                    component: () => import('../pages/ChangeInfo.vue'),
                    meta: {
                        title: '用户设置'
                    }
                },
                {
                    path: '/store',
                    component: () => import('../pages/Shop/Store.vue'),
                    meta: {
                        title: '店铺管理'
                    },
                    name: 'store'
                },
                {
                    path: '/region',
                    component: () => import('../pages/Shop/Region.vue'),
                    meta: {
                        title: '区域管理'
                    }
                },
                {
                    name: 'appletConfig',
                    path: '/appletConfig',
                    component: () => import('../pages/appletConfig/appletConfig.vue'),
                    meta: {
                        title: '小程序首页配置'
                    }
                },
                {
                    name: 'appletConfigList',
                    path: '/appletConfigList',
                    component: () => import('../pages/appletConfig/appletConfigList.vue'),
                    meta: {
                        title: '小程序首页列表'
                    }
                },
                {
                    name: 'appletConfigListTest',
                    path: '/appletConfigListTest',
                    component: () => import('../pages/appletConfig/appletConfigListTest.vue'),
                    meta: {
                        title: '小程序首页测试'
                    }
                },
                {
                    name: 'appleTwoType',
                    path: '/appleTwoType',
                    component: () => import('../pages/appletConfig/appleTwoType.vue'),
                    meta: {
                        title: '小程序二级分类页面'
                    }
                },
                {
                    name: 'appleScreenType',
                    path: '/appleScreenType',
                    component: () => import('../pages/appletConfig/appleScreenType.vue'),
                    meta: {
                        title: '小程序二级筛选'
                    }
                },

                {
                    name: 'detailsManage',
                    path: '/detailsManage',
                    component: () => import('../pages/appletConfig/detailsManage.vue'),
                    meta: {
                        title: '筛选项详情管理'
                    }
                },
                {
                    name: 'detailsConfiguration',
                    path: '/detailsConfiguration',
                    component: () => import('../pages/appletConfig/detailsConfiguration.vue'),
                    meta: {
                        title: '筛选项详情管理'
                    }
                },
                {
                    name: 'facilityManagement',
                    path: '/facilityManagement',
                    component: () => import('../pages/appletConfig/facilityManagement.vue'),
                    meta: {
                        title: '小程序设施管理'
                    }
                },
                {
                    name: 'filterManage',
                    path: '/filterManage',
                    component: () => import('../pages/appletConfig/filterManage.vue'),
                    meta: {
                        title: '筛选项管理'
                    }
                },

                {
                    path: '/promotion',
                    name: 'promotion',
                    component: () => import('../pages/Settled/promotion.vue'),
                    meta: {
                        title: '商家推广位申请'
                    }
                },
                {
                    path: '/account',
                    name: 'account',
                    component: () => import('../pages/Shop/Account.vue'),
                    meta: {
                        title: '账号管理'
                    }
                },
                {
                    path: '/category',
                    name: 'category',
                    component: () => import('../pages/Settled/Category.vue'),
                    meta: {
                        title: '经营类目'
                    }
                },
                {
                    path: '/categoryCreated',
                    name: 'categoryCreated',
                    component: () => import('../pages/Settled/CategoryCreated.vue'),
                    meta: {
                        title: '创建经营类目'
                    }
                },
                {
                    path: '/apply',
                    name: 'apply',
                    component: () => import('../pages/Settled/Apply.vue'),
                    meta: {
                        title: '商户入驻申请列表'
                    }
                },
                {
                    path: '/applyCreated',
                    name: 'applyCreated',
                    component: () => import('../pages/Settled/ApplyCreated.vue'),
                    meta: {
                        title: '商户入驻内部申请'
                    }
                },
                {
                    path: '/accountCreated',
                    name: 'accountCreated',
                    component: () => import('../pages/Shop/AccountCreated.vue'),
                    meta: {
                        title: '账号创建'
                    }
                },
                {
                    path: '/shopmap',
                    name: 'shopmap',
                    component: () => import('../pages/Shop/ShopMap.vue'),
                    meta: {
                        title: '商家地图'
                    }
                },
                {
                    path: '/storecreated',
                    component: () => import('../pages/Shop/StoreCreated.vue'),
                    meta: {
                        title: '创建店铺'
                    }
                },
                {
                    path: '/storeupdated',
                    component: () => import('../pages/Shop/StoreUpdated.vue'),
                    meta: {
                        title: '修改店铺'
                    }
                },
                {
                    path: '/wareHouse/categories',
                    name: 'wareHouseCategories',
                    component: () => import('../pages/Product/WareHouse/Categories.vue'),
                    meta: {
                        title: '商品库类别管理'
                    }
                },
                {
                    path: '/wareHouse/product',
                    name: 'wareHouseCategories',
                    component: () => import('../pages/Product/WareHouse/Product.vue'),
                    meta: {
                        title: '商品库产品管理'
                    }
                },
                {
                    name: 'storeCate',
                    path: '/store/categories/:id',
                    component: () => import('../pages/Product/Store/Categories.vue'),
                    meta: {
                        title: '门店类别管理'
                    }
                },
                {
                    path: '/store/product',
                    name: 'storeProduct',
                    component: () => import('../pages/Product/Store/Product.vue'),
                    meta: {
                        title: '门店产品管理'
                    }
                },
                {
                    name: 'mallCate',
                    path: '/mall/categories',
                    component: () => import('../pages/Product/Mall/Categories.vue'),
                    meta: {
                        title: '商城类别管理'
                    }
                },
                {
                    name: 'mallProduct',
                    path: '/mall/product',
                    component: () => import('../pages/Product/Mall/Product.vue'),
                    meta: {
                        title: '商城商品管理'
                    }
                },
                {
                    path: '/mall/PromoteSales',
                    name: 'mallPromoteSales',
                    component: () => import('../pages/Product/Mall/PromoteSales.vue'),
                    meta: {
                        title: '商城促销商品管理'
                    }
                },
                {
                    path: '/mall/redemption',
                    name: 'mallRedemption',
                    component: () => import('../pages/Product/Mall/Redemption.vue'),
                    meta: {
                        title: '换购管理'
                    }
                },
                {
                    name: 'mallRedemptionProducts',
                    path: '/mall/redemptionProducts',
                    component: () => import('../pages/Product/Mall/RedemptionProducts.vue'),
                    meta: {
                        title: '换购商品管理'
                    }
                },
                {
                    path: '/mall/productToBeAdded',
                    name: 'mall/productToBeAdded',
                    component: () => import('../pages/Product/Mall/ProductToBeAdded.vue'),
                    meta: {
                        title: '待添加换购商品管理'
                    }
                },
                {
                    name: 'couponList',
                    path: '/coupon/coupon_list',
                    component: () => import('../pages/coupon/coupon_list.vue'),
                    props: true,
                    meta: {
                        title: '优惠券列表'
                    }
                },
                {
                    path: '/coupon/coupon_card',
                    name: 'coupon_card',
                    component: () => import('../pages/coupon/coupon_card.vue'),
                    meta: {
                        title: '优惠券创建'
                    }
                },
                {
                    name: 'coupon_group',
                    path: '/coupon/coupon_group',
                    component: () => import('../pages/coupon/coupon_group.vue'),
                    meta: {
                        title: '优惠券包'
                    }
                },
                {
                    name: 'couponGroupList',
                    path: '/coupon/coupon_group_list',
                    component: () => import('../pages/coupon/coupon_group_list.vue'),
                    meta: {
                        title: '优惠券包卡券列表'
                    }
                },
                {
                    name: 'coupon_receive',
                    path: '/coupon/coupon_receive',
                    component: () => import('../pages/coupon/coupon_receive.vue'),
                    meta: {
                        title: '优惠券领取记录'
                    }
                },
                {
                    name: 'createPartner',
                    path: '/createPartner',
                    component: () => import('../pages/partner/createPartner.vue'),
                    meta: {
                        title: '创建合伙人协议'
                    }
                },
                {
                    name: 'createCityPartner',
                    path: '/createCityPartner',
                    component: () => import('../pages/partner/createCityPartner.vue'),
                    meta: {
                        title: '创建区域合伙人'
                    }
                },
                {
                    name: 'middlePartnerList',
                    path: '/middlePartnerList',
                    component: () => import('../pages/partner/middlePartnerList.vue'),
                    meta: {
                        title: '区域合伙人中间的合伙人协议列表'
                    }
                },
                {
                    name: 'partnerList',
                    path: '/partner/partnerList',
                    component: () => import('../pages/partner/partnerList.vue'),
                    meta: {
                        title: '合伙人协议列表'
                    }
                },
                {
                    name: 'fa_question',
                    path: '/counseling_center/fa_questions_module/fa_question',
                    component: () => import('../pages/counseling_center/fa_questions_module/fa_question.vue'),
                    meta: {
                        title: '常见问题列表'
                    }
                },
                {
                    name: 'create_question_detail',
                    path: '/counseling_center/fa_questions_module/create_question_detail',
                    component: () => import('../pages/counseling_center/fa_questions_module/create_question_detail.vue'),
                    meta: {
                        title: '创建常见问题详情'
                    }
                },
                {
                    name: 'cityPartnerList',
                    path: '/partner/cityPartnerList',
                    component: () => import('../pages/partner/cityPartnerList.vue'),
                    meta: {
                        title: '区域合伙人列表'
                    }
                },
                {
                    name: 'buyPartnerList',
                    path: '/partner/buyPartnerList',
                    component: () => import('../pages/partner/buyPartnerList.vue'),
                    meta: {
                        title: '合伙人购买记录'
                    }
                },
                {
                    name: 'appletPictures',
                    path: '/appletPictures',
                    component: () => import('../pages/appletPictures/appletPictures.vue'),
                    meta: {
                        title: '小程序所需要用到的图片'
                    }
                },
                {
                    name: 'createAppletPic',
                    path: '/createAppletPic',
                    component: () => import('../pages/appletPictures/createAppletPic.vue'),
                    meta: {
                        title: '创建图片'
                    }
                },
                {
                    path: '/recharge/recharge',
                    name: 'rechargeRecharge',
                    component: () => import('../pages/Recharge/Recharge.vue'),
                    meta: {
                        title: '充值管理'
                    }
                },
                {
                    path: '/recharge/recharge_order_list',
                    name: 'recharge_order_list',
                    component: () => import('../pages/Recharge/recharge_order_list.vue'),
                    meta: {
                        title: '充值订单列表'
                    }
                },
                {
                    path: '/listusers/listusers',
                    name: 'listusers',
                    component: () => import('../pages/listusers/listusers.vue'),
                    meta: {
                        title: '用戶列表'
                    }
                },
                {
                    path: '/member/member',
                    name: 'member',
                    component: () => import('../pages/Member/Member.vue'),
                    meta: {
                        title: '会员管理'
                    }
                },
                {
                    path: '/subscribe/subscribe',
                    name: 'subscribe',
                    component: () => import('../pages/Subscribe/Subscribe.vue'),
                    meta: {
                        title: '订阅消息'
                    }
                },
                {
                    path: '/payout_management/payout_management',
                    name: 'payout_management',
                    component: () => import('../pages/payout_management/payout_management.vue'),
                    meta: {
                        title: '提现管理'
                    }
                },
                {
                    path: '/payout_management/purchasemembership',
                    name: 'purchasemembership',
                    component: () => import('../pages/payout_management/purchasemembership.vue'),
                    meta: {
                        title: '购买会员'
                    }
                },
                {
                    path: '/payout_management/refund_list',
                    name: 'refund_list',
                    component: () => import('../pages/payout_management/refund_list.vue'),
                    meta: {
                        title: '退款订单列表'
                    }
                },
                {
                    path: '/settings/system_settings',
                    name: 'system_settings',
                    component: () => import('../pages/settings/system_settings.vue'),
                    meta: {
                        title: '系统设置'
                    }
                },
                {
                    path: '/goWeixin/goWeixin',
                    name: 'goWeixin',
                    component: () => import('../pages/goWeixin/goWeixin.vue'),
                    meta: {
                        title: '跳转微信'
                    }
                }
            ]
        },
        {
            path: '/login',
            name: 'login',
            component: () => import(/* webpackChunkName: "login" */ '../pages/Login.vue'),
            meta: {
                title: '登录'
            }
        },
        {
            path: '*',
            redirect: '/404'
        }
    ]
});

// // //router. beforeEach 是全局钩子函数，它是在路由跳转之前所调用的函数，在实际开发中页面进度条的加载、设置页面标题、判断用户是否已经登录过了等等都可以在该函数中执行。
router.beforeEach((to, from, next) => {
    // console.log(to);
    let token = localStorage.getItem('ms_token');
    //   console.log(to)
    if (!token) {
        if (to.name == 'login') {
            next();
        } else {
            next({
                name: 'login'
            });
        }
    }
    next();
});

export default router;
