// 触发保存菜单栏的路径方法
export const savePath = ({ commit }, payload) => {
    commit('savePath', payload);
};

// 触发获取当前点击的数据源方法
export const saveCurrDbSource = ({ commit }, payload) => {
    commit('saveCurrDbSource', payload);
};

// 触发登陆时的保存token的方法
export const saveLoginToken = ({ commit }, payload) => {
    commit('saveLoginToken', payload);
};

// 小程序筛选管理
export const setValueAppleScreenType = ({ commit }, payload) => {
    commit('appleScreenType', payload);
};

// 小程序筛选管理详情
export const setValueDetailsManage = ({ commit }, payload) => {
    commit('detailsManage', payload);
};

// 小程序筛选配置详情
export const setValueDetailsConfiguration = ({ commit }, payload) => {
    commit('detailsConfiguration', payload);
};
export const saveDetialId = ({ commit }, payload) => {
    commit('saveCreateDetailsId', payload);
};